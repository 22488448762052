import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Scenario from '../Scenario/Scenario';
import ScenarioList from '../Scenario/ScenarioList';
import WelcomeModal from '../WelcomeModal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from '../Loader';
import ScenarioListVertical from '../Scenario/ScenarioListVertical';
import Hidden from '@material-ui/core/Hidden';
import ProductList from '../Product/ProductList';
import Globals from '../../Globals.js';
import QuarellaAppBar from '../AppBar';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import './style.css';

export class Decorator extends React.Component {

    constructor(props) {
        super(props);
        this.handleChangeProductsbyScenario = this.handleChangeProductsbyScenario.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleSelectProduct = this.handleSelectProduct.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.useStyles = this.useStyles.bind(this);
        this.addScenarios = this.addScenarios.bind(this);
        this.initCanvasProducts = this.initCanvasProducts.bind(this);
        this.displayLoader = this.displayLoader.bind(this);
        this.state = {
            currentScenarioId: null,
            currentScenarioImg: null,
            currentCategoryId: null,
            currentCategoryName: null,
            scenarios: [],
            products: [],
            categories: [],
            currentProductId: [],
            currentCategoryList: [],
            currentProductsbyScenario: [],
            canvasProducts: [],
            currentTab: 0,
            showLoader: false,
        };
    }

    componentDidMount() {
        Decorator.fetchFirstScenario((scenario) => {
            this.setInitialScenarioAndCategory(scenario, (scenario) => {
                Decorator.fetchProducts(scenario.id, scenario.category_id, (products) => {
                    this.setProducts(products);
                });
            })
        });
    }

    // Fetch requests
    static fetchFirstScenario(_callback) {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("enabled", 1);
        formData.append("limit", 1);
        const requestUrl = Globals.getRequestUrl('scenario/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (Array.isArray(data.fetch)) {
                _callback(data.fetch[0]);
            }
        });
    }
    
    static fetchProducts(scenario_id, category_id, _callback) {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", scenario_id);
        formData.append("category_id", category_id);
        const requestUrl = Globals.getRequestUrl('product/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => { _callback(data.fetch) });
    }
 
    useStyles() {
        return makeStyles((theme) => ({
            root: {
              flexGrow: 1,
            },
        }));
    }

    handleChangeCategory(category_id) {
        this.setState(() => {
            return {
                currentCategoryId: category_id
            };
        });
    }
    handleChangeProductsbyScenario(fetch) {
        this.setState(() => {
            return {
                currentProductsbyScenario: fetch
            };
        });
    }
    handleChangeProduct(product_id) {
        this.setState(() => {
            return {
                currentProductId: product_id
            };
        });
    }

    handleSelectProduct(product) {
        this.setState(prevState => {
            let canvasProducts = Object.assign({}, prevState.canvasProducts);
            for (let index in canvasProducts) {
                let category_id = Object.keys(canvasProducts[index])[0];
                if (category_id === product.category_id) {
                    // 'Porcelanicos' and 'Mosaicos' are supposed to be on the same place on the scenario,
                    // As a quick fix, this deletes the content in the opposite category place.
                    if (category_id === '4'){
                        for (let i in canvasProducts) {
                            let cat = Object.keys(canvasProducts[i])[0];
                            if (cat === '1') {
                                canvasProducts[i][cat] = [];
                            }
                        }
                    } else if (category_id === '1'){
                        for (let i in canvasProducts) {
                            let cat = Object.keys(canvasProducts[i])[0];
                            if (cat === '4') {
                                canvasProducts[i][cat] = [];
                            }
                        }
                    }
                    canvasProducts[index][category_id] = product;
                }
            }
            return { canvasProducts }
        });
    }

    handleTabChange() {
        this.setState({currentTab: this.state.currentTab === 0 ? 1 : 0});
    }

    addScenarios = (fetchedScenarios) => {
        this.setState({scenarios: fetchedScenarios});
    }

    setInitialScenarioAndCategory = (scenario, _callback) =>  {
        this.setState({
            currentScenarioId: scenario.id,
            currentCategoryId: scenario.category_id,
            currentScenarioImg: scenario.image_url,
        }, _callback(scenario));
    }

    setProducts = (fetchedProducts) => {
        this.setState({products: fetchedProducts ? fetchedProducts : []});
    }

    setProduct = (product_id) => {
        this.setState({currentProductId: product_id});
    }

    addCategoriesListbyScenario = (fetchedCategories) => {
        this.setState({categories: fetchedCategories}, function (){
            if(fetchedCategories) {
                this.handleChangeCategory(fetchedCategories[0].id);
                this.initCanvasProducts(fetchedCategories);
            }
        });
    }

    addCategories = (fetchedCategories) => {
        this.setState({categories: fetchedCategories}, function () {
            this.initCanvasProducts(fetchedCategories);
        });
    }

    initCanvasProducts = (categoryList) => {
        if(categoryList) {
            this.setState(() => ({
                canvasProducts: categoryList.map((category) => {
                    return {[category.id]: []};
                })
            }));
        }
    }

    setCategory = (category_id, category_name) => {
        this.setState({currentCategoryName: category_name});
        this.setState({currentCategoryId: category_id});
    }

    setScenario = (selectedScenarioImg, scenarioId) => {
        this.setState({
            currentScenarioImg: selectedScenarioImg,
            currentScenarioId: scenarioId
        });
        this.initCanvasProducts(this.state.categories);
    }

    displayLoader(display) {
        this.setState({showLoader: display});
    }

    render() {
        const classes = this.useStyles();
        return (
            <>
            <Loader open={this.state.showLoader} />
            <QuarellaAppBar/>
            <div className={classes.root}>
                <WelcomeModal></WelcomeModal>
                <Container maxWidth="xl">
                    <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
                        <Grid item xs={12} sm={12} md={9} lg={8}>
                            <Scenario
                                scenarioImage={this.state.currentScenarioImg}
                                categoryId={this.state.currentCategoryId}
                                productId={this.state.currentProductId}
                                canvasProducts={this.state.canvasProducts}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={4}>
                            <Hidden smDown>
                                <ProductList
                                    setProducts={this.setProducts}
                                    setProduct={this.setProduct}
                                    products={this.state.products}
                                    scenarioId={this.state.currentScenarioId}
                                    addCategories={this.addCategories}
                                    categories={this.state.categories}
                                    handleChangeCategory={this.handleChangeCategory}
                                    setCategory={this.setCategory}
                                    categoryName={this.state.currentCategoryName}
                                    categoryId={this.state.currentCategoryId}
                                    handleSelectProduct={this.handleSelectProduct}
                                    displayLoader={this.displayLoader}
                                    imageWidth={270}
                                    imageHeight={180}
                                    imageCrop="fit"
                                />
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <Hidden smDown>
                                <ScenarioList
                                    scenarioId={this.state.currentScenarioId}
                                    addScenarios={this.addScenarios}
                                    setScenario={this.setScenario}
                                    scenarios={this.state.scenarios}
                                    setProducts={this.setProducts}
                                    setProduct={this.setProduct}
                                    addCategoriesList={this.addCategoriesListbyScenario}
                                    currentCategoryId={this.state.currentCategoryId}
                                    handleChangeCategory={this.handleChangeCategory}
                                    displayLoader={this.displayLoader}
                                />
                            </Hidden>
                        </Grid>
                    </Grid>                        
                </Container>
                <Hidden mdUp>
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs 
                                value={this.state.currentTab} 
                                onChange={this.handleTabChange} 
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                >
                                <Tab label="Escenarios" />
                                <Tab label="Productos" />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis='x'
                            index={this.state.currentTab}
                            onChangeIndex={this.handleTabChange}
                        >
                            <div role="tabpanel" index={0} hidden={this.state.currentTab !== 0} id='full-width-tabpanel-0' aria-labelledby='full-width-tab-0'>
                                {this.state.currentTab === 0 && (
                                    <ScenarioListVertical
                                    scenarioId={this.state.currentScenarioId}
                                    addScenarios={this.addScenarios}
                                    setScenario={this.setScenario}
                                    scenarios={this.state.scenarios}
                                    setProducts={this.setProducts}
                                    setProduct={this.setProduct}
                                    addCategoriesList={this.addCategoriesListbyScenario}
                                    currentCategoryId={this.state.currentCategoryId}
                                    handleChangeCategory={this.handleChangeCategory}
                                    />
                                )}
                            </div>

                            <div role="tabpanel" index={1} hidden={this.state.currentTab !== 1} id='full-width-tabpanel-1' aria-labelledby='full-width-tab-1'>
                                {this.state.currentTab === 1 && (
                                    <ProductList
                                    setProducts={this.setProducts}
                                    setProduct={this.setProduct}
                                    products={this.state.products}
                                    scenarioId={this.state.currentScenarioId}
                                    addCategories={this.addCategories}
                                    categories={this.state.categories}
                                    handleChangeCategory={this.handleChangeCategory}
                                    setCategory={this.setCategory}
                                    categoryName={this.state.currentCategoryName}
                                    categoryId={this.state.currentCategoryId}
                                    handleSelectProduct={this.handleSelectProduct}
                                    displayLoader={this.displayLoader}
                                    imageWidth={180}
                                    imageHeight={180}
                                    imageCrop="fill"
                                    />
                                )}
                            </div>
                        </SwipeableViews>
                    </div>
                </Hidden>
            </div>
            </>
          );
    }
}

export default Decorator;