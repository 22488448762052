import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import TutorialModal from './TutorialModal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? '100vw' : '30vw',
    height: isMobile ? '65vh' : 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  tutorialButton: {
    backgroundColor: '#51bed5',
    display: 'block',
    color: '#fff',
    fontWeight: 'bold',
  },
}));

export default function WelcomeModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect (() => {
      setOpen(true);
  }, []);

  const [isTutorialOpen, setTutorialOpen] = React.useState(false);

  const openTutorialDialog = () => {
    setTutorialOpen(true);
  };

  const closeTutorialDialog = () => {
    setTutorialOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <picture>
              <img src="/img/logo.png" alt="start" style={{ width: isMobile ? '100%' : '20vw' }} />
            </picture>
            <h1>Bienvenido</h1>
            <p>A la primera plataforma donde podrás plasmar tus mejores ideas de decoración con los productos de Quarella Decor</p>
            <Button variant="contained" className={classes.tutorialButton} onClick={openTutorialDialog}>Tutorial</Button>
            <TutorialModal afterClose={handleClose} open={isTutorialOpen} onClose={closeTutorialDialog}></TutorialModal>
          </div>
        </Fade>
      </Modal>
     
      </>
  );
}