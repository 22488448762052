import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import {Image} from 'cloudinary-react';
import './style.css';
import Globals from '../../Globals';
import Skeleton from 'react-loading-skeleton';
import ReactGA from 'react-ga';
export class ScenarioListVertical extends React.Component {

    constructor(props) {
        super(props);
        this.fetchScenarios = this.fetchScenarios.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.addScenarios = this.addScenarios.bind(this);
        this.useStyles = this.useStyles.bind(this);
    }

    useStyles() {
        return makeStyles((theme) => ({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.paper,
              },
              gridList: {
                width: 500,
                height: 450,
                flexWrap: 'nowrap',
                cursor: 'pointer',
                // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
                transform: 'translateZ(0)',
              },
              title: {
                color: theme.palette.primary.light,
              },
              titleBar: {
                background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              },
        }));
    }

    componentDidMount() {
        this.fetchScenarios();
    }

    getScenarioImageUrl(scenario_id) {
        return this.state.scenarios[scenario_id].image_url;
    }

    fetchProducts(scenario_id) {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", scenario_id);
        formData.append("category_id", this.props.currentCategoryId);
        const requestUrl = Globals.getRequestUrl('product/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setProducts(data.fetch));
    }

    fetchCategories(scenario_id, _callback) {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("scenario_id", scenario_id);
        formData.append("enabled", 1);
        const requestUrl = Globals.getRequestUrl('category/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setCategoryListbyScenario(data.fetch))
            .then(function(){ _callback() });
    }

    setProducts(fetchedProducts) {
        this.props.setProducts(fetchedProducts);
    }

    setCategoryListbyScenario(fetchedCategories) {
        this.props.addCategoriesList(fetchedCategories);
    }

    fetchScenarios() {
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("enabled", 1);
        const requestUrl = Globals.getRequestUrl('scenario/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.addScenarios(data.fetch));
    }

    addScenarios (fetchedScenarios) {
        this.props.addScenarios(fetchedScenarios);
    }

    setScenario (scenario_img, scenario_id) {
        ReactGA.event({category: 'Usuario', action: 'Seleccionó escenario', value: parseInt(scenario_id)});
        this.props.setScenario(scenario_img, scenario_id);
        this.fetchCategories(scenario_id, () => {
            this.fetchProducts(scenario_id);
        });
        window.scrollTo(0, 0);
    }

    getImagePath(public_id) {
        return Globals.getScenariosCloudPath()+public_id+".jpg"; 
    }

    render() {

        if (!this.props.scenarios || this.props.scenarios.length === 0) {
            return (
                <Skeleton width={'100%'} height={500}/>
            );
        }

        const classes = this.useStyles();
        return (
            <div className={classes.root}>
                <GridList cellHeight={180} className={classes.gridList} >
                    {
                        this.props.scenarios.map((scenario) => (
                            <GridListTile key={scenario.id} onClick={() => this.setScenario(scenario.image_url, scenario.id)}>
                                <Image 
                                    cloudName={Globals.getCloudName()}
                                    publicId={this.getImagePath(scenario.image_public_id)}
                                    width="270"
                                    height="180"
                                    crop="fill"
                                    className="scenario-thumb"
                                ></Image>
                                <GridListTileBar
                                title={scenario.name}
                                classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                }}
                            />
                            </GridListTile>
                        ))
                    }
                </GridList>
            </div>
        )
    }
}

export default ScenarioListVertical;