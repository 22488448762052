import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import CategorySelect from './CategorySelect';
import {Image} from 'cloudinary-react';
import {isMobile} from 'react-device-detect';
import './style.css';
import Globals from '../../Globals';
import Skeleton from 'react-loading-skeleton';
import ReactGA from 'react-ga';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
        width: '100%'
    },
    gridList: {
        width: '100%',
        height: isMobile ? 'auto' : '31.5vw'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});
export class ProductList extends React.Component {

    constructor(props) {
        super(props);
        this.handleSelectProduct = this.handleSelectProduct.bind(this);
        this.fetchProductAttibutes = this.fetchProductAttibutes.bind(this);
    }

    fetchProductAttibutes(product_id) {
        this.props.displayLoader(true);
        let formData = new FormData();
        formData.append("api_token", Globals.getApiToken());
        formData.append("id", product_id);
        formData.append("scenario_id", this.props.scenarioId);
        const requestUrl = Globals.getRequestUrl('product/read');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formData
        };
        fetch(requestUrl, requestOptions)
            .then(response => response.json())
            .then(data => this.setCanvasProduct(data.fetch));
    }

    handleSelectProduct(product_id) {
        ReactGA.event({category: 'Usuario', action: 'Seleccionó producto', value: parseInt(product_id)});
        this.fetchProductAttibutes(product_id);
        if(isMobile) {
            window.scrollTo(0, 0);
        }
    }

    setCanvasProduct(product) {
        this.props.handleSelectProduct(product);
        this.props.displayLoader(false);
    }

    setProducts(fetchedProducts) {
        this.props.setProducts(fetchedProducts);
    }

    getImagePath(public_id) {
        return Globals.getProductsCloudPath()+public_id+".jpg"; 
    }
    
    render() {
        if (!this.props.scenarioId) {
            return (
                <div style={{marginTop: '15px', height: isMobile ? 'auto' : '30.77vw'}}>
                    <Skeleton width={'100%'} height={isMobile ? 40 : 85} />
                    <Skeleton width={'100%'} height={isMobile ? 500 : '84.15%'} style={{marginTop: '15px'}} />
                </div>
            );
        }

        const { classes } = this.props;
        let message = "";
        if (this.props.products.length === 0) {
            message = <ListSubheader component="div">No hay productos</ListSubheader>
        }

        return (<>
            <CategorySelect
            categories={this.props.categories}
            addCategories={this.props.addCategories}
            setProducts={this.props.setProducts}
            setCategory={this.props.setCategory}
            scenarioId={this.props.scenarioId}
            categoryId={this.props.categoryId}
            displayLoader={this.props.displayLoader}
            />
            <div id="product-list" className={classes.root}>
                <GridList cellHeight={180} className={ classes.gridList + " product-list-view" }>
                    { message }
                    {
                        this.props.products.map((product) => (
                            <GridListTile key={product.id} onClick={() => {this.handleSelectProduct(product.id)}}>
                                <Image
                                    cloudName={Globals.getCloudName()}
                                    publicId={this.getImagePath(product.image_public_id)}
                                    width={this.props.imageWidth}
                                    height={this.props.imageHeight ? this.props.imageHeight  : null}
                                    crop={this.props.imageCrop}
                                    className='product-thumb'
                                ></Image>
                                <GridListTileBar title={product.name} />
                            </GridListTile>
                        ))
                    }
                </GridList>
            </div>
        </>);
    }
}

export default withStyles(styles)(ProductList);